<template lang="">
  <div>
    
    <div class="row">
        <div class="col-md-4 col-12">
            <Card class="card dveb-grayheader-card" style="height:100%">
                <template #header>Varlık Durumları</template>
                <PropertyLines />

            </Card>
        </div>
        <div class="col-md-4 col-12">
            <Card class="card dveb-grayheader-card h-full">
                <template #header>Toplam Portföy Tutarı
</template>

                <PortfolioColumn />
            </Card>
        </div>
        <div class="col-md-4 col-12">
            <Card class="card dveb-grayheader-card h-full" style="height:100%">
                <template #header>Banka Varlık Sayıları</template>
                <BankDonut />
            </Card>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
 name: 'charts',
 components: {
    Donut: () => import('@/components/dashboard/charts/donut'),
    Lines: () => import('@/components/dashboard/charts/lines'),
    PropertyLines: () => import('@/components/dashboard/charts/propertyColumn'),
    PortfolioColumn: () => import('@/components/dashboard/charts/portfolioColumn'),
    BankDonut: () => import('@/components/dashboard/charts/bankDonut.vue'),
   },
};
</script>
<style lang=""></style>
